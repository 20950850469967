import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab } from '@headlessui/react'
import DataTable from "../components/Datatable/DataTable";
import { COLOR_PRIMARY } from 'gatsby-env-variables'


// Basic language component
const LanguagePage = ({ pageContext: { language, bibles, resources, films } }) => {

    const { t } = useTranslation()

    const tabs = [
        { name: t('bibles.title'), href: '#', count: (bibles ? bibles.length : 0), current: true },
        { name: t('resources.title'), href: '#', count: (resources ? resources.length : 0), current: false },
        { name: t('films.title'), href: '#', count: (films ? films.length : 0), current: false }
    ]
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

  return (
    <div>

        <Tab.Group>

        <div className="border-b border-gray-200 mb-8">
            <Tab.List>
                <nav className="-mb-px flex space-x-8 justify-center" aria-label="Tabs">
                {tabs.map((tab) => (
                    <Tab key={tab.name} aria-current={tab.current ? 'page' : undefined}>

                        {({ selected }) => (
                            <span
                                className={classNames(
                                    selected ? `border-primary-500 text-primary-600` : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                    'whitespace-nowrap flex py-4 px-1 border-b-2 text-sm'
                                )}
                            >
                                {tab.name}

                                {tab.count ? (
                                    <span className={classNames(
                                        selected ? `bg-primary-100 text-primary-600` : 'bg-gray-100 text-gray-900',
                                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs md:inline-block')}>{tab.count}</span>
                                ) : null}

                            </span>
                        )}

                    </Tab>
                ))}
                </nav>
            </Tab.List>

        </div>

            <Tab.Panels>
                <Tab.Panel>

                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {bibles.map((bible) => (
                        <li key={bible.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 flex flex-col">

                            <div className="w-full flex flex-grow items-center justify-between p-6 space-x-6">
                                <div className="flex-1">
                                    <div className="flex space-x-3">
                                        <h3 className="flex-1 text-gray-900 text-sm">{bible.title}</h3>
                                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-400 text-xs">
                                            {bible.date}
                                        </span>
                                    </div>
                                    <p className="text-gray-500 text-md">{bible.title_vernacular}</p>
                                </div>
                            </div>

                            <div className="flex divide-x divide-gray-200 items-end">
                                <div className="w-0 flex-1 flex">
                                    <a
                                        href={`https://downloads.dbs.org/downloads/pdf/${bible.id}/${bible.id}.pdf`}
                                        target='_blank' rel="noreferrer"
                                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500">
                                        <span className="ml-3">PDF</span>
                                    </a>
                                </div>
                                <div className="-ml-px w-0 flex-1 flex">
                                    <a
                                        href={`https://downloads.dbs.org/downloads/html_zips/${bible.id}.zip`}
                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500">
                                        <span className="ml-3">HTML</span>
                                    </a>
                                </div>
                                <div className="-ml-px w-0 flex-1 flex">
                                    <a
                                        href={`https://downloads.dbs.org/downloads/epub/${bible.id}.epub`}
                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500">
                                        <span className="ml-3">ePub</span>
                                    </a>
                                </div>
                            </div>

                        </li>
                    ))}
                    </ul>

                </Tab.Panel>
                <Tab.Panel>

                    <DataTable
                        data={resources}
                        columns={[
                            {accessor: 'title', Header: t('fields.name'),
                                Cell: ({ row }) => (
                                    <a className={`text-primary-600`} href={row.values.url}>
                                        <div className={`text-primary-600 w-60 overflow-ellipsis overflow-hidden`}>{row.values.title}</div>
                                    </a>
                            )},
                            {accessor: 'url', Header: 'url', hidden:true},
                            {accessor: 'type', Header: t('fields.type')},
                            {accessor: 'author', Header: t('fields.author')},
                        ]} />
                    {/* "iso":"hin","type":"audio","cover":"","title":"Songs","title_vernacular":null,"description":"","url_source":null,"url":"https://globalrecordings.net/en/program/80919","author":"Global Recordings Network","organization_id":"global-recordings-network","date":"" */}

                </Tab.Panel>
                <Tab.Panel>

                    <DataTable
                        data={films}
                        columns={[
                            {accessor: 'title', Header: t('fields.name'),
                                Cell: ({ row }) => (
                                    <a className={`text-primary-600`} href={row.values.url}>
                                        <div className={`text-primary-600 w-60 overflow-ellipsis overflow-hidden`}>{row.values.title}</div>
                                    </a>
                                )},
                            {accessor: 'url', Header: 'url', hidden:true},
                            {accessor: 'author', Header: t('fields.author')},
                        ]} />

                </Tab.Panel>
            </Tab.Panels>



        </Tab.Group>

    </div>)
}

export default LanguagePage